body{
	background-color: #1565c0;
	color: $light-gray;
	font-family: "Helvetica";
}
a{
	color: $light-gray;
}
a:hover{
	color: #fff;
}
p{
	font-size: 1.25rem;
	@include breakpoint(small only){
		font-size: .9rem;
	}
}
span{
	font-size: 1rem;
	@include breakpoint(small only){
		font-size: .75rem;
	}
}

.construction-parent{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
